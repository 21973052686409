import { service } from '@/config/axios'

import { AxiosPromise } from 'axios'

import { useCache } from '@/hooks/web/useCache'
import { config } from '@/config/axios/config'

const {
  defaultHeaders
} = config

export const useAxios = () => {
  const request = <T>(option: AxiosConfig): AxiosPromise<T> => {
    const { url, method, params, data, headersType, responseType } = option
    const { wsCache } = useCache()
    const token = wsCache.get('token')

    return service({
      url: url,
      method,
      params,
      data,
      responseType: responseType,
      headers: {
        'Content-Type': headersType || defaultHeaders,
        Authorization: token
      }
    })
  }

  return {
    request
  }
}
