const config : {
  baseUrl: {
    base: string
    dev: string
    pro: string
    test: string
  }
  resultCode: number | string
  defaultHeaders: AxiosHeaders,
  requestTimeout: number
} = {
  baseUrl: {
    base: '//web-pc.bonqua.cn/',
    dev: '/',
    pro: '',
    test: ''
  },

  resultCode: 200,
  requestTimeout: 60000,
  defaultHeaders: 'application/x-www-form-urlencoded'
}

export { config }
