import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosError, InternalAxiosRequestConfig
} from 'axios'

import { ElMessage } from 'element-plus'
import qs from 'qs'

import { config } from '@/config/axios/config'

const {
  resultCode,
  baseUrl
} = config

const service: AxiosInstance = axios.create({
  baseURL: baseUrl.base,
  timeout: config.requestTimeout
})

// request 拦截器
service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (config.method === 'post' && (config.headers as AxiosRequestHeaders)['Content-Type'] === 'application/x-www-form-urlencoded') {
    config.data = qs.stringify(config.data)
  }

  // get参数编码
  if (config.method === 'get' && config.params) {
    let url = config.url as string

    url += '?'

    const keys: string[] = Object.keys(config.params)

    for (const key of keys) {
      if (config.params[key] !== 0 && config.params[key] !== null) {
        url += `${key}=${encodeURIComponent(config.params[key])}&`
      }
    }

    url = url.substring(0, url.length - 1)

    config.params = {}
    config.url = url
  }

  return config
}, (error: AxiosError) => {
  Promise.reject(error).then(r => {
    console.log(error, r)
  })
})

// response 拦截器
service.interceptors.response.use((response) => {
  if (response.data.code === resultCode) {
    return response.data
  } else {
    ElMessage({
      message: response.data.data.error,
      type: 'error',
      offset: 80
    })
  }
}, (error: AxiosError) => {
  ElMessage.error('err : ' + error)

  return Promise.reject(error)
})

export { service }
